/* eslint-disable no-nested-ternary */
import React from 'react';
import {Button} from '../button/button';
import {Box} from '../box/index';
import {GridList} from '../grid-list/grid-list';
import {Typography} from '../typography/index';
import {ArrowRightIcon} from '../icons';

interface RoundedBorderCardListProps {
  items: CardContent[];
  title: string;
  isMobile?: boolean;
}

type CardContent = {
  header: string;
  description: string;
  handleButtonClick: any;
  buttonText: string;
};

export const RoundedBorderCardList: React.FC<RoundedBorderCardListProps> = ({
  items,
  title,
  isMobile,
}: RoundedBorderCardListProps) => {
  const renderGridItem = (item: CardContent) => {
    return (
      <Box
        padding="six"
        borderStyle="solid"
        borderColor="outlines"
        borderWidth="one"
        height="314px"
        marginLeft={isMobile ? 'zero' : 'four'}
        marginRight={isMobile ? 'zero' : 'four'}
        marginTop={isMobile ? 'four' : 'four'}
        marginBottom={isMobile ? 'four' : 'four'}
        position="relative"
        style={{borderBottomRightRadius: 60}}
      >
        <Box>
          <Typography
            lineHeight="biglead"
            fontWeight="regular"
            fontSize="h2"
            color="textDefault"
          >
            {item.header}
          </Typography>
        </Box>
        <Box marginTop="four" marginBottom="fifteen">
          <Typography
            lineHeight="biglead"
            fontWeight="regular"
            fontFamily="abc"
            fontSize="lead"
            color="textDefault"
          >
            {item.description}
          </Typography>
        </Box>
        <Box bottom="27px" position="absolute">
          <Button
            type="secondary"
            buttonStyle={{
              width: '200px',
            }}
            hasBorder
            onPress={item.handleButtonClick}
            leftAccessory={<ArrowRightIcon color="textDefault" />}
          >
            {item.buttonText}
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      marginTop={isMobile ? 'eight' : 'zero'}
      marginBottom={isMobile ? 'eight' : 'zero'}
    >
      <Box
        marginTop={isMobile ? 'four' : 'four'}
        marginBottom={isMobile ? 'four' : 'four'}
        marginLeft={isMobile ? 'zero' : 'four'}
        marginRight={isMobile ? 'zero' : 'four'}
      >
        <Typography
          lineHeight="biglead"
          fontWeight="regular"
          fontSize="h2"
          color="textLow"
        >
          {title}
        </Typography>
      </Box>
      <GridList
        columns={[1, 1, 3, 3]}
        loading={false}
        items={items}
        keyExtractor={(key) => key.header}
        numOfItems={items.length}
        error={null}
        renderItem={renderGridItem}
        borderWidth="zero"
      />
    </Box>
  );
};
