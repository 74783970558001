import {
  Box,
  HorizontalDivider,
  NFTGhostItem,
  NFTTrackCard,
  Typography,
  VisibilityBox,
} from '@ttx/design-system';
import {useRouter} from 'next/router';
import React, {useCallback} from 'react';
import {getOpenseaUrl} from '../../contexts/authentication-context/consts';
import {WalletNetwork} from '../../contexts/authentication-context/types';
import {StaticNftRecord, StaticNftWithUrlRecord} from '../../graphql/generated';
import {
  NFT as CollectionNFT,
  NftToken,
} from '../../server/router/collection/types';
import {NFT} from '../../server/router/nft/types';
import {BCWrapper} from '../blockchain-props-wrapper/bc-props.wrapper';

interface NFTTrackHorizontalScrollProps {
  isLoading?: boolean;
  header?: string | null;
  subheader?: string | null;
  data?:
    | NftToken[]
    | StaticNftRecord[]
    | StaticNftWithUrlRecord[]
    | CollectionNFT[]
    | NFT[];
  type?: number;
  network?: WalletNetwork | null;
  multiCopy: boolean;
  creatorName?: string | null;
  isStaticData?: boolean;
  hasBorder?: boolean;
}

export const NFTTrackHorizontalScroll: React.FC<
  NFTTrackHorizontalScrollProps
> = ({
  isLoading,
  header,
  subheader,
  data,
  network,
  multiCopy,
  type,
  creatorName,
  isStaticData = false,
  hasBorder = false,
}: NFTTrackHorizontalScrollProps) => {
  const localData = isLoading ? new Array(4).fill(null) : data;
  const router = useRouter();

  const renderCards = useCallback(
    // @ts-expect-error render-card fix
    (nft, i) => {
      if (isStaticData) {
        return (
          <NFTTrackCard
            key={i.toString()}
            src={nft.image.url}
            trackName={nft.title}
            artist={creatorName}
            network={network}
            price={nft.price}
            currencySymbol={nft.currency}
            onPress={() => {
              if (nft.url) {
                router.push(nft.url);
              }
            }}
            state={nft.price ? 'available' : 'coming soon'}
            totalSupply={nft.quantityAvailable}
            hasBorder={hasBorder}
          />
        );
      }
      if (isLoading || !network) return <NFTGhostItem />;
      return (
        <BCWrapper
          nftToken={nft}
          multiCopy={multiCopy}
          network={network}
          key={`/tracks/${nft.slug}`}
          type={type}
        >
          <NFTTrackCard
            key={nft._id}
            src={nft.image}
            trackName={nft.name}
            artist={nft.properties.artist ? nft.properties.artist : creatorName}
            openseaUrl={getOpenseaUrl(
              network,
              nft.contractAddress,
              nft.tokenId,
            )}
            hasBorder={hasBorder}
          />
        </BCWrapper>
      );
    },
    [creatorName, isLoading, isStaticData, multiCopy, network, type],
  );

  return (
    <Box>
      <VisibilityBox show={!!header || !!subheader}>
        <Box mt="sixteen" mb="lg">
          <VisibilityBox show={!!header}>
            <Box ml={['md', 'lg']} mb={['md', 'lg']}>
              <Typography color="textDefault" textStyle={['h2', 'h2', 'h1']}>
                {header}
              </Typography>
            </Box>
          </VisibilityBox>
          <VisibilityBox show={!!subheader}>
            <Box ml={['md', 'lg']} css={{'margin-top': '-12px'}}>
              <Typography color="textLow" textStyle={['h2', 'h2', 'h1']}>
                {subheader}
              </Typography>
            </Box>
          </VisibilityBox>
        </Box>
      </VisibilityBox>
      <HorizontalDivider />
      <Box
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="100%"
        overflowX="scroll"
      >
        {localData?.map((nft: NftToken, idx: number) => (
          <Box
            width={['239px', '299px', '359px']}
            height="100%"
            borderRightColor="outlines"
            borderRightStyle="solid"
            borderRightWidth={
              idx !== localData.length - 1 || localData.length < 6
                ? 'one'
                : 'zero'
            }
            borderTopWidth="zero"
            borderBottomWidth="zero"
            key={`${idx.toString()}`}
          >
            {renderCards(nft, idx)}
          </Box>
        ))}
      </Box>
      <HorizontalDivider />
    </Box>
  );
};
