import React from 'react';
import {theme} from '../theme/theme';
import {Box, AnimatedBox} from '../box';
import {VisibilityBox} from '../box/visibility-box';
import {FrameworkLink} from '../link-renderer';
import {Typography} from '../typography';
import {ArrowRightIcon} from '../icons';
import {useNFTTrackCardAnimation} from '../nft-track-card/useNFTTrackCardAnimation';
import {useSpring} from '../animation';

export interface HeaderImageProps {
  name: string;
  href: string;
  disabled?: boolean;
}

export const HeaderImage: React.FC<HeaderImageProps> = ({
  name,
  href,
  disabled,
}: HeaderImageProps) => {
  const {hovered, hoveredStyle, events} = useNFTTrackCardAnimation();

  const animatedAccessoryStyle = useSpring({
    opacity: hovered ? 1 : 0,
    width: hovered ? '50px' : '0px',
  });
  const finalHoveredStyle = disabled ? {} : hoveredStyle;

  const renderedContent = (
    <AnimatedBox
      {...events}
      marginTop="zero"
      width="100%"
      padding="four"
      justifyContent="flex-end"
      zIndex="0"
      style={
        {
          ...finalHoveredStyle,
        } as any
      }
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="50%"
        css={{
          background: theme.colors.gradientTop,
        }}
      />
      <Box
        position="absolute"
        top="50%"
        left="0"
        right="0"
        bottom="0"
        css={{
          background: theme.colors.gradientPrimary,
          transform: 'rotate(-180deg)' as any,
        }}
      />
      <Box>
        <Typography fontFamily="abc" textStyle={['h2']} color="textLow">
          Featured artist
        </Typography>
        <Box flexDirection="row" alignItems="center" css={{marginTop: '4px'}}>
          <VisibilityBox show={!disabled}>
            <AnimatedBox style={animatedAccessoryStyle}>
              <ArrowRightIcon color="outlines" size={45} />
            </AnimatedBox>
          </VisibilityBox>
          <Typography
            fontFamily="abc"
            textStyle={['heroSmall']}
            color="textDefault"
          >
            {name}
          </Typography>
        </Box>
      </Box>
    </AnimatedBox>
  );
  if (disabled) return renderedContent;

  return <FrameworkLink href={href}>{renderedContent}</FrameworkLink>;
};
