import React from 'react';
import Head from 'next/head';

const DEFAULT_IMAGE =
  'https://www.datocms-assets.com/73624/1655239388-favicon.png';

const DEFAULT_URL = 'https://www.tokentraxx.com/';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  openGraphType?: string;
  url: string;
  createdAt?: string;
  updatedAt?: string;
  schemaType?: string;
}

const socialTags = ({
  openGraphType,
  url,
  title,
  description,
  image,
  createdAt,
  updatedAt,
}: SEOProps) => {
  const metaTags = [
    {name: 'twitter:card', content: 'summary_large_image'},
    {
      name: 'twitter:site',
      content: 'TokenTraxx',
    },
    {name: 'twitter:title', content: title},
    {name: 'twitter:description', content: description},
    {name: 'twitter:image:src', content: image},
    {name: 'twitter:card', content: 'summary_large_image'},
    {name: 'og:title', content: title},
    {name: 'og:type', content: openGraphType || 'website'},
    {name: 'og:url', content: url},
    {name: 'og:image', content: image},
    {name: 'og:description', content: description},
    {
      name: 'og:site_name',
      content: 'Token||Traxx',
    },
    {
      name: 'og:published_time',
      content: createdAt,
    },
    {
      name: 'og:modified_time',
      content: updatedAt,
    },
  ];

  return metaTags;
};

export const SEO: React.FC<SEOProps> = ({
  title = 'TokenTraxx',
  description = 'The main stage for music in Web3. Connecting artists and fans to mint the sound of a decentralised future.',
  image = DEFAULT_IMAGE,
  openGraphType = 'website',
  schemaType = 'article',
  url = DEFAULT_URL,
  createdAt = new Date().toISOString(),
  updatedAt = new Date().toISOString(),
}: SEOProps) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />
      {socialTags({
        title,
        description,
        image,
        openGraphType,
        url,
        createdAt,
        updatedAt,
      }).map(({name, content}) => {
        return <meta key={name} name={name} content={content} />;
      })}
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': schemaType,
            name: title,
            about: description,
            url,
          }),
        }}
      />
    </Head>
  );
};
