import React from 'react';
import {RoundedBorderCardList} from '@ttx/design-system';

interface DocumentationSectionProps {
  handleRedirect: (arg: string) => void;
  isMobile: boolean;
}

export const DocumentationSection: React.FC<DocumentationSectionProps> = ({
  handleRedirect,
  isMobile,
}: DocumentationSectionProps) => {
  const whatWeDoSection = [
    {
      header: 'Roadmap',
      description: 'Learn more about what’s coming for TokenTraxx.',
      handleButtonClick: () => handleRedirect('/about/mission?ref=roadmap'),
      buttonText: 'View roadmap',
    },
    {
      header: 'Team',
      description: 'Meet the team behind TokenTraxx.',
      handleButtonClick: () => handleRedirect('/about/mission?ref=team'),
      buttonText: 'View team',
    },
    {
      header: 'Docs',
      description:
        'Read more on our business model, platform functionality, technology and tokenomics or Gitbook.',
      handleButtonClick: () => handleRedirect('https://docs.tokentraxx.com/'),
      buttonText: 'Visit Gitbook',
    },
  ];
  return (
    <RoundedBorderCardList
      isMobile={isMobile}
      title="Documentation"
      items={whatWeDoSection}
    />
  );
};
