import React from 'react';
import {Box} from '../box';
import {ScrollMarquee} from '../scroll-marquee/index.web';
import {Typography} from '../typography';

export const BasicTextScroll: React.FC<{text: string}> = ({text}) => {
  return (
    <ScrollMarquee play speed={90} gradient={false}>
      <Box marginY="s">
        <Typography textStyle="s" color="textDefault">
          {text.repeat(20)}
        </Typography>
      </Box>
    </ScrollMarquee>
  );
};
