import React, {useMemo} from 'react';
import {Box} from '../box';
import {VisibilityBox} from '../box/visibility-box';
import {GridBox} from '../grid-box';
import {GridBoxProps} from '../grid-box/types';
import {useGridList} from './use-grid-list';

export interface GridListProps<Item extends any, Error extends any = any>
  extends GridBoxProps {
  keyExtractor: (item: Item, index: number) => string;
  renderItemContainer?: (
    children: React.ReactNode,
    key: string,
    item: Item,
    index: number,
  ) => React.ReactNode;
  renderItem: (
    item: Item,
    key: string,
    index: number,
    items: Item[],
  ) => React.ReactNode;
  renderLoadingComponent?: () => React.ReactNode;
  renderBackgroundComponent?: () => React.ReactNode;
  renderErrorComponent?: (error: Error) => React.ReactNode;
  items: Item[];
  loading: boolean;
  isBackground?: boolean;
  error: Error;
  children?: never;
  numOfItems: number;
}

export const GridList = <Item extends any>({
  keyExtractor,
  renderItemContainer = (children) => children,
  renderItem,
  renderLoadingComponent,
  renderErrorComponent,
  renderBackgroundComponent,
  items,
  loading,
  error,
  isBackground = false,
  numOfItems,
  ...rest
}: GridListProps<Item>): ReturnType<React.FC<GridListProps<Item>>> => {
  const {finalCellSpan} = useGridList({
    columns: rest.columns,
    numOfItems,
  });

  const renderedItems = useMemo(
    (): React.ReactNode =>
      items.map((item, index, array) => {
        if (index === items.length - 1 && finalCellSpan !== 0) {
          return (
            <React.Fragment
              key={`${keyExtractor(item, index)}-${finalCellSpan}`}
            >
              <Box flex={1} backgroundColor="surfaceDark">
                {/* @ts-expect-error render fix */}
                {renderItemContainer(
                  renderItem(item, keyExtractor(item, index), index, array),
                  keyExtractor(item, index),
                  item,
                  index,
                )}
              </Box>
              <Box
                flex={1}
                width="100%"
                css={{gridColumnEnd: `span ${finalCellSpan}`}}
                backgroundColor="surfaceDark"
              />
            </React.Fragment>
          );
        }
        return (
          <Box
            flex={1}
            backgroundColor="surfaceDark"
            key={keyExtractor(item, index)}
          >
            <>
              {renderItemContainer(
                renderItem(item, keyExtractor(item, index), index, array),
                keyExtractor(item, index),
                item,
                index,
              )}
            </>
          </Box>
        );
      }),
    [items, keyExtractor, renderItem, renderItemContainer, finalCellSpan],
  );

  const renderedComponent = useMemo(() => {
    if (loading) return renderLoadingComponent?.();
    if (isBackground) return renderBackgroundComponent?.();
    return renderedItems;
  }, [
    isBackground,
    loading,
    renderBackgroundComponent,
    renderLoadingComponent,
    renderedItems,
  ]);

  const hasErrored = error && !loading;

  return (
    <Box position="relative">
      <VisibilityBox show={isBackground}>
        <Box
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          backgroundColor="surfaceDark"
          opacity={0.9}
          zIndex={1}
        />
      </VisibilityBox>
      {hasErrored && renderErrorComponent?.(error)}
      <GridBox {...(rest as any)}>{renderedComponent}</GridBox>
    </Box>
  );
};
