/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-nested-ternary */
import {Box, Typography} from '@ttx/design-system';
import React, {useEffect, useState} from 'react';

interface PressReleaseSectionProps {
  isMobile: boolean;
}

const PressReleaseItems = [
  {
    alt: 'The Telegraph',
    width: '195px',
    height: '40px',
    img: '/press-section/The telegraph.png',
    isLeft: true,
    link: 'https://www.telegraph.co.uk/art/what-to-see/smoke-clearing-nft-bubble-bursting-may-great-news-artists/',
  },
  {
    alt: 'Forbes',
    width: '126px',
    height: '40px',
    isLeft: true,
    img: '/press-section/Forbes.png',
    link: 'https://www.forbes.com/sites/joshwilson/2022/03/18/oscar-and-grammy-winner-paul-epworth-joins-music-nft-ecosystem-tokentraxx/',
  },
  {
    alt: 'Crypto Intelligence',
    width: '280px',
    height: '33px',
    isLeft: true,
    img: '/press-section/Cryptointelligence.png',
    link: 'https://www.cryptointelligence.co.uk/three-most-exciting-music-nft-platforms-to-keep-an-eye-on-in-2022/',
  },
  {
    alt: 'RTechRound',
    width: '162px',
    height: '40px',
    isLeft: false,
    img: '/press-section/TechRound.png',
    link: 'https://techround.co.uk/startups/token-traxx-next-big-thing/',
  },
  {
    alt: 'Music Week',
    width: '148px',
    height: '37px',
    isLeft: false,
    img: '/press-section/musicweek.png',
    link: 'https://www.musicweek.com/digital/read/kwame-kwaten-launches-web3-resource-for-breaking-into-the-music-business/086991',
  },
  // {
  //   alt: 'Investing.com',
  //   width: '216px',
  //   height: '41px',
  //   isLeft: false,
  //   img: '/press-section/investing.png',
  //   link: 'https://www.investing.com/news/cryptocurrency-news/tokentraxx-raises-7m-gets-support-from-major-global-pension-funds-2804542',
  // },
  // {
  //   alt: 'Yahoo',
  //   width: '156px',
  //   height: '48px',
  //   img: '/press-section/Yahoo.png',
  //   link: 'https://uk.style.yahoo.com/psychoyp-releases-500-fan-passports-112000932.html',
  // },
  // {
  //   alt: 'The FinTech Times',
  //   width: '100%',
  //   height: '40px',
  //   img: '/press-section/Fintech times.png',
  //   link: 'https://thefintechtimes.com/musical-nft-platform-fills-gap-in-the-market-by-combining-a-streaming-service-with-selling-nfts/',
  // },
  // {
  //   alt: 'Decrypt',
  //   width: '182px',
  //   height: '66px',
  //   img: '/press-section/Decrypt.png',
  //   link: 'https://decrypt.co/94675/how-token-traxx-rewards-music-creators-curators-and-collectors',
  // },
  // {
  //   alt: 'Music Times',
  //   width: '162px',
  //   height: '40px',
  //   isLeft: true,
  //   img: '/press-section/Musictimes.png',
  //   link: 'https://www.musictimes.com/articles/86817/20220912/an-interview-with-richard-rauser-tokentraxx-head-of-innovation-and-co-creator-of-traxxstemz.htm',
  // },
  // {
  //   alt: 'The Face',
  //   width: '170px',
  //   height: '26px',
  //   isLeft: false,
  //   img: '/press-section/the face.png',
  //   link: 'https://theface.com/music/tokentraxx-founder-tommyd-interview-nfts-music-crypto-ministry-of-sound-music-kanye-west',
  // },
  // {
  //   alt: 'Coin Market Cap',
  //   width: '289px',
  //   height: '55px',
  //   img: '/press-section/Coinmarket cap.png',
  //   link: 'https://coinmarketcap.com/headlines/news/tokentraxx-moves-through-the-gears-with-key-senior-appointments/',
  // },
  // {
  //   alt: 'NFT Culture',
  //   width: '235px',
  //   height: '26px',
  //   img: '/press-section/NFTculture.png',
  //   link: 'https://www.nftculture.com/nft-news/international-electronic-artist-sasha-brings-together-twenty-artists-for-a-special-nft-collaboration-to-celebrate-10-years-of-his-label-last-night-on-earth/',
  // },
  // {
  //   alt: 'CEO Today',
  //   width: '131px',
  //   height: '67px',
  //   img: '/press-section/Ceotoday.png',
  //   link: 'https://www.ceotodaymagazine.com/2022/09/building-in-the-winter/',
  // },
  // {
  //   alt: 'Coin Telegraph',
  //   width: '270px',
  //   height: '69px',
  //   isLeft: true,
  //   img: '/press-section/Cointelegraph.png',
  //   link: 'https://cointelegraph.com/news/the-future-of-music-is-on-chain-in-a-conversation-with-tokentraxx-co-founder',
  // },
];

const SubItems = ({
  link,
  alt,
  img,
  width,
  height,
  isLeft,
}: {
  link: string;
  alt: string;
  img: string;
  width: string;
  height: string;
  isLeft: boolean | undefined;
}) => {
  const restStyle: any =
    isLeft === true
      ? {left: '0px'}
      : isLeft === undefined
      ? {}
      : {right: '0px'};
  return (
    <Box
      flex={1}
      alignItems="center"
      marginLeft="one"
      marginRight="one"
      marginBottom="ten"
    >
      <a href={link} target="_blank" rel="noreferrer">
        <img
          src={img}
          alt={alt}
          style={{
            height,
            width,
            maxHeight: '66px',
            ...restStyle,
          }}
        />
      </a>
    </Box>
  );
};

const ViewMap = ({data}: any) => {
  return (
    <>
      <Box flexDirection="row" flexWrap="wrap">
        {data.map((e: any) => (
          <SubItems key={e.alt} {...e} />
        ))}
      </Box>
    </>
  );
};

export const PressReleaseSection: React.FC<PressReleaseSectionProps> = ({
  isMobile,
}: PressReleaseSectionProps) => {
  const [view, setView] = useState<any>(null);

  const setViewUI = () => {
    let tempView;
    if (window.innerWidth > 1400) {
      tempView = (
        <Box flexDirection="row">
          {PressReleaseItems.map((e) => (
            <Box flex={1} key={e.alt}>
              <a href={e.link} rel="noreferrer" target="_blank">
                <img
                  alt={e.alt}
                  src={e.img}
                  style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                  height="40px"
                  width="auto"
                />
              </a>
            </Box>
          ))}
        </Box>
      );
    } else {
      tempView = (
        <div style={{display: 'flex', maxWidth: '100vw', overflowX: 'scroll'}}>
          {PressReleaseItems.map((e) => (
            <div style={{marginRight: '42px'}} key={e.alt}>
              <a href={e.link} rel="noreferrer" target="_blank">
                <img alt={e.alt} src={e.img} height="40px" width="auto" />
              </a>
            </div>
          ))}
        </div>
      );
    }
    setView(tempView);
  };

  useEffect(() => {
    setViewUI();
    window.addEventListener('resize', () => {
      setViewUI();
    });
    return () => {
      window.removeEventListener('resize', () => {
        setViewUI();
      });
    };
  }, []);

  return (
    <Box marginTop="four" marginBottom="four">
      <Box>
        <Box marginBottom="ten">
          <Typography
            lineHeight="biglead"
            fontWeight="regular"
            color="textLow"
            fontSize="h2"
          >
            As seen in:
          </Typography>
        </Box>
        <Box marginBottom="four">
          {/* <Box>
            {PressReleaseItems.map((e) => (
              <Box flex={1}>
                <a href={e.link}>
                  <img alt={e.alt} src={e.img} height="40px" width={e.width} />
                </a>
              </Box>
            ))}
          </Box> */}
          {view}
          {/* <Box flexDirection="row">
            {PressReleaseItems.map((e) => (
              <FrameworkLink key={e.alt} href={e.link}>
                <Box
                  width={isMobile ? '162px' : '240px'}
                  height={isMobile ? '23px' : '35px'}
                  marginBottom="one"
                >
                  <FrameworkImage alt={e.alt} layout="fill" src={e.img} />
                </Box>
              </FrameworkLink>
            ))}
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};
