import React, {useMemo} from 'react';
import {Box} from '../box';
import {FrameworkImage} from '../image-renderer';
import {HorizontalDivider} from '../divider/horizontal-divider';
import {VerticalDivider} from '../divider/vertical-divider';
import {Typography} from '../typography';
import {Button} from '../button/button';
import {theme} from '../theme/theme';
import {useBreakpoint} from '../use-breakpoint';
import {NetworkIconProps, ArrowRightIcon, CurrencyIconProps} from '../icons';
import {formatDate} from '../nft-details-panel/utils';
import {BasicTextScroll} from '../basic-text-scroll';
import {NFTState} from '../nft-track-card/types';

export interface HeroNFTPropsV2 {
  image: string;
  header?: string | null;
  subheader?: string | null;
  scrollText?: string | null;
  description: string;
  network?: NetworkIconProps['name'] | null;
  dateAdded?: Date;
  totalSupply?: string | null;
  artistHandle?: string | null;
  isSold?: boolean;
  multiCopy?: boolean;
  onPress?: () => void;
  onPressOverride?: () => void;
  price?: string | null;
  currencySymbol?: CurrencyIconProps['name'] | null;
  buttonCta?: string | null;
  state?: NFTState;
}

export const HeroNFTV2: React.FC<HeroNFTPropsV2> = ({
  image,
  header,
  subheader,
  scrollText,
  description,
  network,
  dateAdded,
  totalSupply,
  artistHandle,
  isSold,
  multiCopy,
  price,
  buttonCta,
  currencySymbol,
  onPress,
  onPressOverride,
  state,
}: HeroNFTPropsV2) => {
  const {currentBreakpoint} = useBreakpoint();
  const isMobile = currentBreakpoint?.toString() === theme.breakpoints[0];
  const isTablet = currentBreakpoint?.toString() === theme.breakpoints[1];
  const isMobileOrTablet = isMobile || isTablet;

  const TableRow: React.FC<{
    children: React.ReactNode;
    hasBottomBorder?: boolean;
  }> = ({children, hasBottomBorder = true}) => {
    return (
      <Box
        flexDirection="row"
        borderBottomColor="outlines"
        borderBottomWidth={hasBottomBorder ? 'one' : 'zero'}
        borderBottomStyle="solid"
        paddingX={['three', 'four']}
        paddingY="three"
      >
        <>{children}</>
      </Box>
    );
  };

  const totalSupplyMessage = useMemo(() => {
    if (state === 'traxxStemz') return '3030 available';
    if (state === 'emilg') return '25 available';
    if (state === 'coming soon') return 'Coming Soon';
    if (multiCopy) return totalSupply ? `Edition of ${totalSupply}` : ``;
    if (!isSold) return '1 available';
    return '0 available';
  }, [state, multiCopy, totalSupply, isSold]);

  const tableContents = useMemo(() => {
    switch (state) {
      case 'coming soon':
        return (
          <>
            {description ? (
              <TableRow>
                <Box flexWrap="wrap" width="100%">
                  <Typography textStyle="s" color="textDefault">
                    {description}
                  </Typography>
                  {dateAdded && artistHandle && (
                    <Typography textStyle="s" color="textDefault">
                      Added on {formatDate(dateAdded)} by {artistHandle}
                    </Typography>
                  )}
                </Box>
              </TableRow>
            ) : null}
          </>
        );
      default:
        return (
          <>
            {artistHandle && artistHandle.toLowerCase() !== 'web4' && (
              <TableRow>
                <Box flex={[4, 3]}>
                  <Box>
                    <Typography textStyle="s" color="textLow">
                      Price
                    </Typography>
                    <Typography textStyle="h2" color="textDefault">
                      {!Number(price) ? 'Free' : price} {currencySymbol}
                    </Typography>
                  </Box>
                </Box>
              </TableRow>
            )}
            {description ? (
              <TableRow>
                <Box flexWrap="wrap" width="100%">
                  <Typography textStyle="s" color="textDefault">
                    {description}
                  </Typography>
                  {dateAdded && artistHandle && (
                    <Typography textStyle="s" color="textLow">
                      Added on {formatDate(dateAdded)} by {artistHandle}
                    </Typography>
                  )}
                </Box>
              </TableRow>
            ) : null}
          </>
        );
    }
  }, [
    artistHandle,
    currencySymbol,
    dateAdded,
    description,
    network,
    price,
    state,
    totalSupplyMessage,
  ]);

  const formattedScrollText = useMemo(() => {
    if (state === 'coming soon') return `Coming soon - `;
    if (scrollText) {
      return `${scrollText} - `;
    }
    return null;
  }, [scrollText, state]);

  return (
    <Box>
      {formattedScrollText ? (
        <>
          <HorizontalDivider />
          <BasicTextScroll text={formattedScrollText} />
        </>
      ) : null}
      <HorizontalDivider />
      <Box flexDirection={['column', 'column', 'row']}>
        <Box flex={['1', '1']} width={['100%', 'auto']}>
          <FrameworkImage
            src={image}
            alt="artist banner"
            width={625}
            height={620}
            quality={40}
          />
        </Box>
        {isMobileOrTablet ? <HorizontalDivider /> : <VerticalDivider />}
        <Box
          flex={!isMobileOrTablet ? '2' : undefined}
          justifyContent="space-between"
        >
          <Box flex={1}>
            <Box m={['md', 'lg']}>
              <Typography color="textLow" textStyle="h2">
                {header}
              </Typography>
              <Typography color="textDefault" textStyle="heroSmall">
                {subheader}
              </Typography>
            </Box>
            <HorizontalDivider />
            {tableContents}
          </Box>
          <Box
            mt={
              artistHandle && artistHandle.toLowerCase() === 'web4'
                ? 'zero'
                : 'fifteen'
            }
          >
            {artistHandle && artistHandle.toLowerCase() !== 'web4' && (
              <HorizontalDivider />
            )}
            <Button
              onPress={onPressOverride || onPress}
              type="primary"
              buttonStyle={{margin: '8px 6px 8px 6px'}}
              leftAccessory={<ArrowRightIcon color="surfaceDark" />}
            >
              {buttonCta || 'See more'}
            </Button>
          </Box>
        </Box>
      </Box>
      <HorizontalDivider />
    </Box>
  );
};
