import {useMemo} from 'react';
import {SpringValue, useSpring} from '../animation';
import {InputState, useInteractionState} from '../button/use-interaction-state';

export const useNFTTrackCardAnimation: (opts?: {
  startOpacity: number;
  scrollWidth?: number;
  textWidth?: number;
  artistTextScrollWidth?: number;
  artistTextWidth?: number;
}) => {
  hovered: boolean;
  hoveredStyle: {
    opacity: number | any;
  };
  events: {
    onHoverIn: () => void;
    onHoverOut: () => void;
  };
  textScrollStyle: {
    transform: SpringValue<string>;
  };
  artistTextScrollStyle: {
    transform: SpringValue<string>;
  };
} = (opts) => {
  const {
    state,
    events: {onHoverIn, onHoverOut},
  } = useInteractionState();

  const hovered = state === InputState.Hovering;

  const hoveredStyle = useSpring({
    opacity: hovered ? 1 : opts?.startOpacity || 0.84,
  });

  const useScrollStyle = (
    scrollWidth: number | undefined,
    textWidth: number | undefined,
  ) =>
    useSpring({
      config: {duration: hovered ? 10 * (scrollWidth || 0) : 1000},
      from: {transform: 'translateX(0px)'},
      to: {
        transform: hovered
          ? `translateX(-${(scrollWidth || 0) - (textWidth || 0) + 12}px)`
          : 'translateX(0px)',
      },
      delay: hovered ? 600 : 0,
    });

  const artistTextScrollStyle = useScrollStyle(
    opts?.artistTextScrollWidth,
    opts?.artistTextWidth,
  );
  const textScrollStyle = useScrollStyle(opts?.scrollWidth, opts?.textWidth);

  return useMemo(
    () => ({
      hovered,
      hoveredStyle,
      textScrollStyle,
      artistTextScrollStyle,
      events: {
        onHoverIn,
        onHoverOut,
      },
    }),
    [
      artistTextScrollStyle,
      hovered,
      hoveredStyle,
      onHoverIn,
      onHoverOut,
      textScrollStyle,
    ],
  );
};
