/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import React, {useCallback} from 'react';
import {UnstyledHtmlAnchor} from '..';
import {Box} from '../box/index';
import {Typography} from '../typography/index';

interface MediaIconSectionProps {
  items: MediaIcon[];
  title: string;
  isMobile?: boolean;
  artistMobile?: boolean;
}

export interface MediaIcon {
  url: string;
  icon: React.ReactNode;
}

const IconWarpper = (item: any, artistMobileIcon?: boolean) => {
  return (
    <>
      {artistMobileIcon ? (
        <Box margin="two">
          <UnstyledHtmlAnchor
            href={item.url}
            style={{display: 'block'}}
            target="_blank"
            rel="noreferrer"
          >
            <Box>{item.icon}</Box>
          </UnstyledHtmlAnchor>
        </Box>
      ) : (
        <Box margin="six">
          <UnstyledHtmlAnchor
            href={item.url}
            style={{display: 'block'}}
            target="_blank"
            rel="noreferrer"
          >
            <Box>{item.icon}</Box>
          </UnstyledHtmlAnchor>
        </Box>
      )}
    </>
  );
};

export const MediaIconSection: React.FC<MediaIconSectionProps> = ({
  items,
  title,
  isMobile,
  artistMobile,
}: MediaIconSectionProps) => {
  const renderComponent = useCallback(() => {
    return (
      <>
        {!isMobile && !artistMobile && (
          <Box flexDirection="row" flexWrap="wrap">
            {items?.map((e, i) => (
              <IconWarpper key={`${e.url}_${i}`} {...e} />
            ))}
          </Box>
        )}
        {isMobile && !artistMobile && (
          <>
            <Box flexDirection="row" flexWrap="wrap">
              {items.slice(0, 4).map((e, i) => (
                <IconWarpper key={`${e.url}_${i}`} {...e} />
              ))}
            </Box>
            <Box flexDirection="row" flexWrap="wrap">
              {items.slice(4, items.length).map((e, i) => (
                <IconWarpper key={`${e.url}_${i}`} {...e} />
              ))}
            </Box>
          </>
        )}
        {artistMobile && (
          <Box flexDirection="column" flexWrap="wrap">
            {items?.map((e, i) => (
              <IconWarpper artistMobileIcon key={`${e.url}_${i}`} {...e} />
            ))}
          </Box>
        )}
      </>
    );
  }, [items, artistMobile, isMobile]);
  return (
    <Box alignItems="center">
      {title && (
        <Box margin="three" marginBottom="five">
          <Typography fontSize="h2" fontWeight="regular" color="textDefault">
            {title}
          </Typography>
        </Box>
      )}
      {renderComponent()}
    </Box>
  );
};
