import React from 'react';
import {RoundedBorderCardList} from '@ttx/design-system';

interface WhatWeDoSectionProps {
  handleRedirect: (arg: string) => void;
  isMobile: boolean;
}

export const WhatWeDoSection: React.FC<WhatWeDoSectionProps> = ({
  handleRedirect,
  isMobile,
}: WhatWeDoSectionProps) => {
  const whatWeDoSection = [
    {
      header: 'Web3 Music Advisory Services',
      description:
        'Advisory service for brands wanting to use Web3 music as part of their growth plans.',
      handleButtonClick: () => handleRedirect(`/advisory`),
      buttonText: 'More on Advisory',
    },
    {
      header: 'Marketplace',
      description:
        'The TokenTraxx Marketplace is your gateway to amazing Web3 audio visual artwork.',
      handleButtonClick: () => handleRedirect('/discover'),
      buttonText: 'Visit Marketplace',
    },
    {
      header: 'Protocol',
      description:
        'Enter Web3 with an edge and launch your minting studio on TRAXX Protocol.',
      handleButtonClick: () => handleRedirect(`/protocol`),
      buttonText: 'More on Protocol',
    },
  ];
  return (
    <RoundedBorderCardList
      isMobile={isMobile}
      title="What we do"
      items={whatWeDoSection}
    />
  );
};
