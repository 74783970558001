import React from 'react';
import {
  ArrowRightIcon,
  Box,
  Button,
  FrameworkImage,
  Typography,
} from '@ttx/design-system';

interface LearnMoreSectionProps {
  handleRedirect: (arg: string) => void;
  isMobile: boolean;
}

export const LearnMoreSection: React.FC<LearnMoreSectionProps> = ({
  handleRedirect,
  isMobile,
}: LearnMoreSectionProps) => {
  const CardContainer = () => {
    return (
      <>
        <Box
          height={isMobile ? '340px' : '300px'}
          width={isMobile ? '340px' : '300px'}
        >
          <FrameworkImage
            src="/ttroundborder.png"
            alt="background"
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Box
          marginTop="five"
          marginLeft={isMobile ? 'zero' : 'seven'}
          marginBottom="five"
        >
          <Box top={isMobile ? '0px' : '30%'}>
            <Box marginBottom="four">
              <Typography
                lineHeight="biglead"
                fontWeight="regular"
                color="textDefault"
                fontSize={['h2', 'heroSmall']}
              >
                TRAXX Token
              </Typography>
            </Box>
            <Box width="100%">
              <Typography
                lineHeight="biglead"
                fontWeight="regular"
                fontFamily="abc"
                color="textDefault"
                fontSize="lead"
              >
                TRAXX is the native token of TokenTraxx, powering everything we
                do.
              </Typography>
            </Box>

            <Box marginTop="eight">
              <Button
                buttonStyle={{
                  width: '200px',
                }}
                onPress={() => handleRedirect(`/about/traxxtokens`)}
                type="primary"
                leftAccessory={<ArrowRightIcon color="textInvert" />}
              >
                Learn more
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box marginTop="ten" marginBottom="ten">
      <Box flexDirection={!isMobile ? 'row' : 'column'}>{CardContainer()}</Box>
    </Box>
  );
};
