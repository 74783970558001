/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ArrowRightIcon,
  Box,
  Button,
  FrameworkImage,
  Typography,
} from '@ttx/design-system';
import React from 'react';

interface HomeHeaderSectionProps {
  handleWhatWeDoClick: () => void;
  handleRedirect: (arg: string) => void;
  isMobile: boolean;
}

export const HomeHeaderSection = ({
  handleWhatWeDoClick,
  handleRedirect,
  isMobile,
}: HomeHeaderSectionProps) => {
  return (
    <Box
      position="relative"
      overflow="hidden"
      height={isMobile ? '460px' : '630px'}
    >
      <Box flexDirection="row" position="absolute" height="100%" width="100%">
        <Box flex={1} />
        <div
          style={{
            flex: '5',
            backgroundImage: 'url(/home-page-blocks.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: 'bottom',
            backgroundPositionX: isMobile ? '0px' : '200px',
          }}
        />
      </Box>
      <Box
        height={isMobile ? '460px' : '700px'}
        top="25%"
        marginLeft={isMobile ? 'zero' : 'twelve'}
        width={isMobile ? '100%' : '455px'}
        backgroundColor="transparent"
      >
        <Box
          width={isMobile ? '162px' : '240px'}
          height={isMobile ? '23px' : '35px'}
          marginBottom="one"
        >
          <FrameworkImage
            src="/logo-tt-text.png"
            alt="background"
            layout="fill"
          />
        </Box>
        <Box width={isMobile ? '280px' : '100%'}>
          <Typography
            fontWeight="regular"
            color="textDefault"
            fontSize={['h2', 'heroSmall']}
          >
            the main stage for music in Web3
          </Typography>
        </Box>

        <Box marginTop="eight">
          <Button
            type="primary"
            leftAccessory={<ArrowRightIcon color="textInvert" />}
            onPress={handleWhatWeDoClick}
          >
            What we do
          </Button>
        </Box>
        <Box marginTop="four">
          <Button
            type="tertiary"
            onPress={() => handleRedirect('/about/mission')}
            hasBorder
            leftAccessory={<ArrowRightIcon color="textDefault" />}
          >
            Our Mission
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
