import {parseUnits, toNativeCurrencyString} from '@ttx/core';
import {useRouter} from 'next/router';
import React, {ReactElement, JSXElementConstructor, useMemo} from 'react';
import {WalletNetwork} from '../../contexts/authentication-context/types';
import {useSmartContractContext} from '../../contexts/smart-contract/context';
import {NFT} from '../../server/router/nft/types';
import {NFT as CollectionNFT} from '../../server/router/collection/types';
import {traxxStemzSlug, emilGSlug, joshSavageSlug} from '../../utils/consts';

interface MembershipInfo {
  isMember: boolean;
  percent: number;
}

export const BCWrapper: React.FC<{
  multiCopy: boolean;
  network: WalletNetwork;
  type?: number;
  nftToken: NFT | CollectionNFT;
  membershipInfo?: MembershipInfo | undefined;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}> = ({multiCopy, network, type, nftToken, membershipInfo, children}) => {
  const router = useRouter();
  const {getCurrencySymbolFromAddress} = useSmartContractContext();
  const {percent} = membershipInfo || {
    percent: 0,
  };
  const {
    quantity,
    status,
    tokenId,
    price,
    originalQuantity,
    currency,
    contractAddress,
    _id,
    slug,
  } = nftToken;

  const isMembership = type === 2;
  const isSold = price && !quantity;

  const currencySymbol = currency
    ? getCurrencySymbolFromAddress(currency as `0x${string}`, network)
    : null;

  const formattedPrice =
    price && currencySymbol
      ? Number(toNativeCurrencyString(price, currencySymbol))
      : 0;

  const discountValue = isMembership
    ? 0
    : formattedPrice * (percent / 100) || 0;
  const subtractedFromDiscount = (formattedPrice - discountValue).toFixed(4);
  const buyoutPrice = price
    ? toNativeCurrencyString(
        parseUnits(subtractedFromDiscount.toString(), 18),
        currencySymbol,
      )
    : null;
  // Not mutually exclusive
  const isTraxxStemz = type === 5;
  const isEmilG = type === 7;
  const isDraft = status === 1;
  const isJoshSavage = type === 8;

  const blindMint = !isTraxxStemz && !isEmilG && !isJoshSavage;

  // TODO: refactor blockchain props wrapper for cards vs hero cards vs nft details panel
  const state = useMemo(() => {
    if (slug === 'life-without-water') return 'sold out';
    if (isMembership) return 'membership';
    if (isTraxxStemz) return 'traxxStemz';
    if (isJoshSavage) return 'joshSavage';
    if (isEmilG) return 'emilg';
    if (isDraft) return 'coming soon';
    if (price === null && blindMint) return undefined;
    if (!price && blindMint) return 'coming soon';
    if (isSold) return 'sold out';
    return 'available';
  }, [
    isSold,
    price,
    isDraft,
    isTraxxStemz,
    isJoshSavage,
    isEmilG,
    isMembership,
  ]);

  const onPress = () => {
    if (isTraxxStemz) return router.push(`/traxxstemz/${traxxStemzSlug}`);
    if (isJoshSavage) return router.push(`/josh-savage/${joshSavageSlug}`);
    if (isEmilG) return router.push(`/emilg/${emilGSlug}`);
    return router.push(`/tracks/${slug}`);
  };

  return React.cloneElement(children, {
    onPress,
    totalSupply: originalQuantity.toString(),
    currencySymbol,
    price: !blindMint ? formattedPrice : buyoutPrice,
    isSold,
    multiCopy,
    network,
    tokenId: tokenId.toString(),
    state,
    contractAddress,
    availableSupply: quantity?.toString(),
  });
};
