import {ArrowRightIcon, Box, Button, Typography} from '@ttx/design-system';
import React from 'react';

interface MarketPlaceSectionProps {
  handleRedirect: (arg: string) => void;
  isMobile: boolean;
}

export const MarketPlaceSection: React.FC<MarketPlaceSectionProps> = ({
  handleRedirect,
  isMobile,
}: MarketPlaceSectionProps) => {
  const ActionButton = () => {
    return (
      <>
        <Button
          buttonStyle={{
            margin: isMobile ? '0px' : '6px',
            width: isMobile ? '100%' : '200px',
          }}
          type="primary"
          leftAccessory={<ArrowRightIcon color="textInvert" />}
          onPress={() => handleRedirect('/discover')}
        >
          View all NFTs
        </Button>
        <Button
          buttonStyle={{
            margin: isMobile ? '0px' : '6px',
            marginTop: isMobile ? '10px' : '6px',
            width: isMobile ? '100%' : '200px',
          }}
          type="secondary"
          leftAccessory={<ArrowRightIcon color="textDefault" />}
          onPress={() => handleRedirect('/artists')}
          hasBorder
        >
          View artists
        </Button>
      </>
    );
  };

  return (
    <Box marginTop="ten" marginBottom="ten">
      <Box marginTop="five" width={isMobile ? '100%' : '500px'}>
        <Box marginBottom="four">
          <Typography
            lineHeight="biglead"
            fontWeight="regular"
            color="textDefault"
            fontSize={['h2', 'heroSmall']}
          >
            Marketplace
          </Typography>
        </Box>
        <Box marginBottom="four">
          <Typography
            lineHeight="biglead"
            fontWeight="regular"
            fontFamily="abc"
            color="textDefault"
            fontSize="lead"
          >
            Collect, create, curate or enjoy exploring the latest releases in
            Web3 music.
          </Typography>
        </Box>
        <Box marginTop="four" flexDirection={isMobile ? 'column' : 'row'}>
          {ActionButton()}
        </Box>
      </Box>
    </Box>
  );
};
