import React from 'react';
import {
  DiscordIcon,
  InstagramIcon,
  MediaIconSection,
  MediumIcon,
  TelegramIcon,
  TiktokIcon,
  TwitterIcon,
  YouTubeIcon,
} from '@ttx/design-system';

interface ConnectWithUsSectionProps {
  isMobile: boolean;
}

export const ConnectWithUsSection: React.FC<ConnectWithUsSectionProps> = ({
  isMobile,
}: ConnectWithUsSectionProps) => {
  const items = [
    {
      url: 'https://twitter.com/TokenTraxx',
      icon: <TwitterIcon size={32} color="textDefault" />,
    },
    {
      url: 'https://discord.com/invite/JyA7H4DyjG',
      icon: <DiscordIcon size={32} color="textDefault" />,
    },
    {
      url: 'https://t.me/TokenTraxxMusic',
      icon: <TelegramIcon size={32} color="textDefault" />,
    },
    {
      url: 'https://medium.com/tokentraxx',
      icon: <MediumIcon size={32} color="textDefault" />,
    },
    {
      url: 'https://www.instagram.com/tokentraxx/',
      icon: <InstagramIcon size={32} color="textDefault" />,
    },
    {
      url: 'https://www.tiktok.com/@tokentraxx',
      icon: <TiktokIcon size={32} color="textDefault" />,
    },
    {
      url: 'https://www.youtube.com/channel/UCoBqzOCTafhAfs1-afCesnQ',
      icon: <YouTubeIcon size={32} color="textDefault" />,
    },
  ];
  return (
    <MediaIconSection
      isMobile={isMobile}
      title="Connect with us"
      items={items}
    />
  );
};
