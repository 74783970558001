import {useLayoutEffect, useMemo, useState} from 'react';
import {theme} from '../theme/theme';
import {useBreakpoint} from '../use-breakpoint';

export const useGridList: ({
  columns,
  numOfItems,
}: {
  columns: number | number[];
  numOfItems: number;
}) => {
  finalCellSpan: number;
} = ({columns, numOfItems}) => {
  const {getResponsiveValue} = useBreakpoint();
  const [finalCellSpan, setFinalCellSpan] = useState(0);

  const columnsArray = useMemo(() => {
    if (typeof columns === 'number') {
      return new Array(theme.breakpoints.length).fill(columns);
    }
    return columns;
  }, [columns]);

  useLayoutEffect(() => {
    const spanPerBreakpoint = columnsArray.map((numOfColumns) => {
      if (numOfItems < numOfColumns) return numOfColumns - numOfItems;
      return numOfColumns - (numOfItems % numOfColumns);
    });

    setFinalCellSpan(getResponsiveValue(spanPerBreakpoint as any[]));
  }, [columnsArray, getResponsiveValue, numOfItems]);

  return {finalCellSpan};
};
